import { useEffect, useState } from "react";
import { Content } from "../../../components/content/content";
import { Footer } from "../../../components/footer/footer";
import { Nav } from "../../../components/nav/nav";
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import PhoneInputWithCountrySelect, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { Bkg } from "../../../components/content/bkg";
import { t } from "../../../utils/cms";
import CountryList from '../../../assets/json/countries.json';
import { Link, useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import { ReactComponent as EyeIcon } from '../../../assets/svgs/eye.svg';
import { ReactComponent as EyeSlashIcon } from '../../../assets/svgs/eye-slash.svg';
import { ReactComponent as IdCardIcon } from '../../../assets/svgs/id-card.svg';
import { ReactComponent as CameraIcon } from '../../../assets/svgs/camera.svg';
import RangeSlider from "../../../components/pages/register/range-slider";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import UnderMaintenance from "../../../components/modal/under-maintenance";
import ProgressSteps from "../../../components/pages/register/progress-steps.component";
import { TermsText } from "./terms";
import { VerifyOTP } from "./verify-otp";
import { QUERY_GET_SUMSUB_WEB_SDK_LINK } from "../../../graphql/queries/sumsub-link";
import "../../../assets/styles/kyc.css"
import { User, UserStatus } from "../../../__generated__/graphql";

interface Step {
  step: number;
  label: string;
  title?: string;
  description?: string;
}

const PrioritisedCountries = [
  "GB",
  "US",
  "CA",
  "FR",
  "DE",
  "IT",
  "ES",
]

const steps: Step[] = [
  {
    step: 1,
    label: "Personal\nDetails",
    title: "Tell Us About Yourself",
    description: "Enter your details to set up your account."
  },
  {
    step: 2,
    label: "Investor\nProfile",
    title: "Investor Details",
    description: "Provide your investment details to proceed."
  },
  {
    step: 3,
    label: "Term &\nServices",
    title: "Terms & Services",
    description: "Review and accept the terms to continue."
  },
  {
    step: 4,
    label: "Verify\nOTP",
    title: "Verification",
    description: "Please verify either your phone or email to continue."
  },
  {
    step: 5,
    label: "KYC",
    title: "Let's Verify Your Identity",
    description: "To deposit or make an investment, we require you to complete our KYC verification."
  }
];

const Countries = PrioritisedCountries.map(code => ({
  code,
  name: CountryList.find(country => country.code === code)?.name
})).concat(CountryList.filter(country => !PrioritisedCountries.includes(country.code)).map(country => ({
  code: country.code,
  name: country.name
})));

export function AccountRegisterPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("UK");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isChecked, setIsChecked] = useState({
    over18: false,
    termsAgreed: false,
    qualifiedInvestor: false,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [aggression, setAggression] = useState('Conservative');
  const [duration, setDuration] = useState(2);
  const [deviceId, setDeviceId] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(1);
  const [iframeUrl, setIframeUrl] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const QUERY_GET_ME = gql`query getMe {
    me {
      firstName
      lastName
      email
      phone
      investorProfile {
        id
        solanaWalletAddress
      }
      status
    }
  }`

  const [getMe, { refetch, data: user, loading: userLoading, error: userError }] = useLazyQuery<{ me: User }>(QUERY_GET_ME, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}` // or sessionStorage or cookies
      },
    },
  });
  const [getSumsubWebSdkLink, { data: sumsubVerifyUrl, loading: sumsubLoading, error: sumsubError },] = useLazyQuery(QUERY_GET_SUMSUB_WEB_SDK_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.sumsubWebSdkLinkFromParams) {
        setIframeUrl(data.sumsubWebSdkLinkFromParams);
      }
    },
  });


  const [register, { data: registerData, error: registerError }] = useMutation(
    gql`
      mutation registerUser(
        $email: String!
        $phone: String!
        $password: String!
        $firstName: String!
        $lastName: String!
        $country: String!
        $investmentDuration: Float
        $investmentApproach: String
        $deviceId: String!
        $osVersion: String!
        $deviceType: String!
        $model: String!
      ) {
        register(
          data: {
            email: $email
            phone: $phone
            password: $password
            firstName: $firstName
            lastName: $lastName
            country: $country
            investmentDuration: $investmentDuration
            investmentApproach: $investmentApproach
          }
          deviceData: {
            deviceId: $deviceId
            osVersion: $osVersion
            deviceType: $deviceType
            model: $model
          }
        ) {
          id
          firstName
          lastName
          email
        }
      }
    `,
    { errorPolicy: "all" }
  );

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function validateEmail(emailTest: string) {
    return emailRegex.test(emailTest);
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setIsChecked(prevState => ({
      ...prevState,
      [id]: checked,
    }));
  };

  useEffect(() => {
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        setDeviceId(result.visitorId);
      });
    });
  }, []);

  // Start querying user every 2 seconds if iframeUrl is present and user status is not Active
  useEffect(() => {
    if (iframeUrl && user && user.me.status !== UserStatus.Active) {
      const id = setInterval(() => {
        getMe(); // Re-fetch user data every 2 seconds
      }, 2000);

      setIntervalId(id);

      return () => {
        clearInterval(id); // Clean up interval when component unmounts or conditions change
      };
    }

    // Redirect to investor portal when user status becomes Active
    if (user && user.me.status === UserStatus.Active) {
      const investor_portal = process.env.REACT_APP_INVESTOR_PORTAL_URL;
      if (investor_portal) {
        window.location.href = investor_portal;
      }
    }

  }, [iframeUrl, user?.me.status, getMe]);

  // iframe is getting predefined styles from sumsub, which is making it disappear from our website. 
  //they were forcefully applied by them which override our css styles too
  //this useEffect is responsible to handle that issue
  useEffect(() => {
    const iframe = document.querySelector('.kyc-iframe-container iframe') as HTMLIFrameElement | null;

    if (iframe) {
      iframe.style.position = 'relative';
      iframe.style.left = '0';

      // Setting up a MutationObserver to handle changes to the style attribute
      const observer = new MutationObserver(() => {
        iframe.style.position = 'relative';
        iframe.style.left = '0';
      });

      observer.observe(iframe, { attributes: true, attributeFilter: ['style'] });

      return () => observer.disconnect();
    }
  }, [iframeUrl]);

  function validatePersonalData() {
    setErrorMessage(''); // Clear previous error messages
    if (!firstName || !lastName || !email || !phone || !password || !country) {
      setLoading(false);
      setErrorMessage(t("accountRegister.Form Feedback.form_validation_error_account_register"));
      return;
    }

    if (!validateEmail(email)) {
      setLoading(false);
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (password.length < 8) {
      setLoading(false);
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }

    // Validate phone number
    if (!isValidPhoneNumber(phone)) {
      setLoading(false);
      setErrorMessage(t("accountRegister.Form Feedback.form_phone_error_account_register"));
      return;
    }

    // If all validations pass, move to the next step
    setCurrentStep((prevStep) => prevStep + 1);
    setErrorMessage(''); // Clear error message
  }

  const userAgent = navigator.userAgent;
  const osVersion = getOSVersion(userAgent);
  const deviceType = getDeviceType(userAgent);
  const model = getModel(userAgent);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setInfoMessage(undefined);
    setErrorMessage(undefined);
    setLoading(true);
    setSubmitted(false);

    const { over18, termsAgreed, qualifiedInvestor } = isChecked;
    if (!over18 || !termsAgreed || !qualifiedInvestor) {
      setLoading(false);
      setErrorMessage("Please accept all agreements to create your account.");
      return;
    }

    setInfoMessage(t("accountRegister.Form Feedback.form_info_message_account_register"));

    // Submit form
    const data = {
      firstName,
      lastName,
      phone: phone,
      password,
      email,
      country,
      investmentDuration: duration,
      investmentApproach: aggression,
    };

    const deviceData = {
      deviceId,
      osVersion,
      deviceType,
      model,
    };

    await register({ variables: { ...data, ...deviceData } });
  }

  useEffect(() => {
    if (registerError) {
      console.error("Registration error:", registerError);
      setLoading(false);
      setInfoMessage(undefined);
      setErrorMessage(registerError.message);
    } else if (registerData) {
      setLoading(false);
      setSubmitted(true);
      setInfoMessage("Your request has been submitted successfully. Please check your email for the next steps.");
      localStorage.setItem("deviceId", deviceId);
      localStorage.setItem("userId", registerData.register?.id);
      setInfoMessage("Account created successfully!");

      if (currentStep < steps.length) {
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
      }
      setErrorMessage('');
    }
  }, [registerData, registerError]);

  useEffect(() => {
    setErrorMessage('');
  }, [currentStep]);

  function handleSumsubVerification() {
    const id = localStorage.getItem("userId");
    if (id) {
      setUserId(id);
      getMe();
      getSumsubWebSdkLink({ variables: { userId: id } });
    }
    else {
      console.error("userId not found");
      setInfoMessage("Please log in to complete verification");
    }
  }

  const onAggressionChange = (value: string | number) => {
    setAggression(value as string);
  };

  const onDurationChange = (value: string | number) => {
    setDuration(value as number);
  };

  function getOSVersion(userAgent: string) {
    let osVersion = 'Unknown OS Version';

    if (/Windows NT 10.0/.test(userAgent)) osVersion = 'Windows 10';
    else if (/Windows NT 6.3/.test(userAgent)) osVersion = 'Windows 8.1';
    else if (/Windows NT 6.2/.test(userAgent)) osVersion = 'Windows 8';
    else if (/Windows NT 6.1/.test(userAgent)) osVersion = 'Windows 7';
    else if (/Mac OS X/.test(userAgent)) {
      const match = userAgent.match(/Mac OS X (\d+[\._]\d+[\._]?\d*)/);
      if (match) osVersion = `macOS ${match[1].replace(/_/g, '.')}`;
    } else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android\s([0-9\.]*)/);
      if (match) osVersion = `Android ${match[1]}`;
    } else if (/iPhone OS/.test(userAgent)) {
      const match = userAgent.match(/iPhone OS (\d+[\._]\d+)/);
      if (match) osVersion = `iOS ${match[1].replace(/_/g, '.')}`;
    }

    return osVersion;
  }

  function getDeviceType(userAgent: string) {
    if (/android/i.test(userAgent)) return 'android';
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) return 'ios';
    return 'browser';
  }


  function getModel(userAgent: string) {
    let model = 'Unknown Model';

    if (/iPhone/.test(userAgent)) model = 'iPhone';
    else if (/iPad/.test(userAgent)) model = 'iPad';
    else if (/Macintosh/.test(userAgent)) model = 'Mac';
    else if (/Windows NT/.test(userAgent)) model = 'Windows PC';
    else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android.*;\s([a-zA-Z0-9\s]+)\sBuild/);
      if (match) model = match[1];
    }

    return model;
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage('');
  }

  return (
    <>
      <Helmet>
        <title>{t("accountRegister.Helmet.helmet_title_account_register")}</title>
        <meta name={t("accountRegister.Helmet.helmet_meta_name_account_register")} content={t("accountRegister.Helmet.helmet_meta_content_account_register")} />
      </Helmet>

      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />

      <Nav />

      <Bkg type="bottom" />

      <Content className="flex account flex-column">
        <h2>{t("accountRegister.Intro.intro_heading_account_register")}</h2>
        <p>{t("accountRegister.Intro.intro_description_account_register")}</p>

        <div className="flex flex-1 mb-5 flex-column stepContent">
          <h3>{t("accountRegister.Form General.form_heading_account_register")}</h3>

          <ProgressSteps
            steps={steps}
            currentStep={currentStep}
            direction="horizontal"
            hideNavigation
            onStepChange={setCurrentStep}
          />
          <form className="gap" onSubmit={handleSubmit}>

            {!registerData ? (
              <>
                {currentStep === 1 &&
                  <>
                    <div className="gap name-container ">
                      <div className="flex flex-column flex-1">
                        <label htmlFor="first-name">{t("accountRegister.Form Fields First Name.first_name_label_account_register")}</label>
                        <input
                          type="text"
                          id="first-name"
                          name="first-name"
                          data-test="first-name"
                          placeholder={t("accountRegister.Form Fields First Name.first_name_placeholder_account_register")}
                          autoFocus
                          onChange={(e) => { setFirstName(e.currentTarget.value); setErrorMessage(''); }}
                          value={firstName}
                        />
                      </div>
                      <div className="flex flex-column flex-1">
                        <label htmlFor="last-name">{t("accountRegister.Form Fields Last Name.last_name_label_account_register")}</label>
                        <input
                          type="text"
                          id="last-name"
                          name="last-name"
                          data-test="last-name"
                          placeholder={t("accountRegister.Form Fields Last Name.last_name_placeholder_account_register")}
                          onChange={(e) => { setLastName(e.currentTarget.value); setErrorMessage(''); }}
                          value={lastName}
                        />
                      </div>
                    </div>
                    <div className="flex flex-column">
                      <label htmlFor="email">{t("accountRegister.Form Fields Email.email_label_account_register")}</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        data-test="email"
                        placeholder={t("accountRegister.Form Fields Email.email_placeholder_account_register")}
                        onChange={(e) => { setEmail(e.currentTarget.value); setErrorMessage(''); }}
                        value={email}
                      />
                    </div>
                    <div className="flex flex-column">
                      <label htmlFor="password">{t("accountRegister.Form Fields Password.password_label_account_register")}</label>
                      {/* <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder={t("accountRegister.Form Fields Password.password_placeholder_account_register")}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                /> */}
                      <div className="password-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          value={password}
                          name="password"
                          data-test="password"
                          placeholder={t("accountRegister.Form Fields Password.password_placeholder_account_register")}
                          onChange={(e) => { setPassword(e.currentTarget.value); setErrorMessage(''); }}
                          className="password-input"
                        />
                        <button
                          type="button"
                          className="password-toggle-button"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <EyeIcon className="icon" /> : <EyeSlashIcon className="icon" />}
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-column">
                      <label htmlFor="phone">{t("accountRegister.Form Fields Phone.phone_label_account_register")}</label>
                      <PhoneInputWithCountrySelect
                        defaultCountry="GB"
                        placeholder={t("accountRegister.Form Fields Phone.placeholder_phone_account_register")}
                        id="phone"
                        name="phone"
                        data-test="phone"
                        value={phone}
                        onChange={(e) => { setPhone(e ? e.toString() : ""); setErrorMessage(''); }}
                      />
                    </div>
                    <div className="flex flex-column">
                      <label htmlFor="country">{t("accountRegister.Form Fields Country.label_country_residence_account_register")}</label>
                      <select
                        id="country"
                        name="country"
                        data-test="country-select"
                        onChange={(e) => { setCountry(e.currentTarget.value); setErrorMessage(''); }}
                      >
                        {Countries.map((country, index) => (
                          <option key={`${country.code}-${index}`} value={country.code}>{country.name}</option>
                        ))}
                      </select>
                    </div>
                    {infoMessage && <div className="info-message">{infoMessage}</div>}
                    {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <div className="error-message">{errorMessage}</div>}

                    <div className="actions">
                      <div data-test="continue-btn" className="btn btn-primary btn-square btn-full-width" onClick={validatePersonalData}>
                        Continue
                      </div>
                    </div>
                  </>
                }

                {currentStep === 2 &&
                  <>
                    <div className="sliderSecion">
                      <RangeSlider
                        title="Overall Investing Approach"
                        startTitle="Conservative"
                        midTitle="Medium"
                        endTitle="Aggressive"
                        isOverall={true}
                        onValueChange={onAggressionChange}
                        data-test="range-slider-overall-investing"
                      />
                      <div className="divider-dark"></div>
                      <RangeSlider
                        title="Investment Duration Plan"
                        startTitle="2 Years"
                        endTitle="7 Years"
                        isOverall={false}
                        onValueChange={onDurationChange}
                        data-test="range-slider-investment-duration"
                      />
                    </div>

                    <div className="actions">
                      <div data-test="back-btn" className="btn btn-white btn-square btn-full-width" onClick={() => setCurrentStep((prevStep) => prevStep - 1)}>
                        Back
                      </div>
                      <div data-test="continue-btn" className="btn btn-primary btn-square btn-full-width" onClick={() => setCurrentStep((prevStep) => prevStep + 1)}>
                        Continue
                      </div>
                    </div>
                  </>
                }

                {currentStep === 3 &&
                  <>
                    <div className="terms-text-container">
                      <TermsText />
                    </div>
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        id="over18"
                        name="over18"
                        data-test="over18"
                        checked={isChecked.over18}
                        onChange={handleCheckboxChange}
                        className="custom-checkbox"
                      />
                      <label htmlFor="over18" className="checkbox-label">
                        I am over the age of 18.
                      </label>
                    </div>

                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        id="qualifiedInvestor"
                        name="qualifiedInvestor"
                        data-test="qualified-investor"
                        checked={isChecked.qualifiedInvestor}
                        onChange={handleCheckboxChange}
                        className="custom-checkbox"
                      />
                      <label htmlFor="qualifiedInvestor" className="checkbox-label">
                        I confirm I am a suitably qualified investor.
                        <a href="/qualified-investor" className="learn-more-link" target="_blank" rel="noopener noreferrer">Learn more</a>
                      </label>
                    </div>

                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        id="termsAgreed"
                        name="termsAgreed"
                        data-test="terms-agreed"
                        checked={isChecked.termsAgreed}
                        onChange={handleCheckboxChange}
                        className="custom-checkbox"
                      />
                      <label htmlFor="termsAgreed" className="checkbox-label">
                        I agree to the Terms of Service.
                        <a href="/terms" className="learn-more-link" target="_blank" rel="noopener noreferrer">
                          Terms of Service
                        </a>
                      </label>
                    </div>
                    {infoMessage && <div className="info-message">{infoMessage}</div>}
                    {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <div className="error-message">{errorMessage}</div>}


                    <div className="actions">
                      <div data-test="back-btn" className="btn btn-white btn-square btn-full-width" onClick={() => setCurrentStep((prevStep) => prevStep - 1)}>
                        Back
                      </div>
                      <button type="submit" data-test="register-btn" className="btn btn-primary btn-square btn-full-width" disabled={loading}>
                        {loading ? "Saving" : "Register"}
                      </button>
                    </div>
                  </>
                }
              </>
            ) : (
              <>
                {/* <h3>{t("accountRegister.Next Steps.next_steps_heading_account_register")}</h3>
              <p>{t("accountRegister.Next Steps.next_steps_description_account_register")}</p>
              <ul>
                <li>
                  <b>{t("accountRegister.Next Steps Items Identity Document.title_identity_document_account_register")}</b> {t("accountRegister.Next Steps Items Identity Document.description_identity_document_account_register")}
                </li>
                <li>
                  <b>{t("accountRegister.Next Steps Items Selfie Verification.title_selfie_verification_account_register")}</b> {t("accountRegister.Next Steps Items Selfie Verification.description_selfie_verification_account_register")}
                </li>
              </ul>
              <p>{t("accountRegister.Next Steps.next_steps_closing_account_register")}</p>
              <p>{t("accountRegister.Next Steps.next_steps_privacy_account_register")}</p> */}
              </>
            )}
          </form>

          {currentStep === 4 &&
            <VerifyOTP
              currentStep={currentStep}
              onStepChange={setCurrentStep}
            />
          }

          {currentStep === 5 &&
            <>
              {!iframeUrl &&
                <div>
                  <div className="verification-container">
                    <div className="verification-item">
                      <IdCardIcon className="verification-icon" />
                      <div className="verification-text">
                        <p className="verification-title">ID Card Verification</p>
                        <p className="verification-description">We will ask you to upload your ID document</p>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="verification-item">
                      <CameraIcon className="verification-icon" />
                      <div className="verification-text">
                        <p className="verification-title">Selfie Verification</p>
                        <p className="verification-description">We will ask you to take a selfie to confirm it's you</p>
                      </div>
                    </div>
                  </div>
                  <div className="important-note">
                    <p className="heading">Important Note</p>
                    <p className="description ">
                      This quick process ensures your account's security and compiles with regulations, allowing you to seamlessly invest with peace of mind
                    </p>
                  </div>
                  <div
                    className="p-8 btn btn-square btn-full-width"
                    onClick={() => handleSumsubVerification()}
                  >
                    Start verification
                  </div>
                </div>
              }
              {iframeUrl && (
                <div className="kyc-iframe-container">
                  <iframe
                    src={iframeUrl}
                    title="KYC Verification"
                    width="100%"
                    height={700}
                    allow="camera; microphone"
                    frameBorder="0"
                  ></iframe>
                </div>
              )}
            </>

          }
        </div>
      </Content>

      <Footer />
    </>
  );
}
